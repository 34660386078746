/* footer */

footer{
    background-color: rgb(85, 165, 199);
    font-size: 0.9rem;
    color: black;
}

.footer__container{
    text-align: center;
    margin: auto;

}
.logoone{
        width: 300px;
        align-items: center;
        margin: auto;
}
.footer__links{
    margin-top: 80px;
}

.footer__pr{
    font-size: 12px;
}
.footer__container article{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;

}
.footer__container article p {
    margin-top: 0.5rem;
    width: 80%;
    margin: auto;
}

.footer__container article h4{
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer_social{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: auto;
    margin-top: 1.6rem;
}

.footer_social a {
    background-color: rgb(85, 165, 199);
    padding: 0.8rem;
    border: 1px solid transparent;
    border-radius:20px;
}

.footer_social a svg{
    color: black;
}


.copyright{
    color: black;
    text-align: center;
    padding: 1.5rem 0;
    border-top:  2px solid white;
    margin-top: 5rem;
}

.div_links{
    display: flex;
}

.social_media{
    width: 50px;
    border: 2px solid rgb(192, 188, 188);
    padding: 10px;
}




/* media screen  mobile view */


@media screen and (max-width : 460px) {
    footer{
        background-color: rgb(85, 165, 199);
        width: 100%;
        font-size: 0.9rem;
        color: black;
    }
    
    .footer__container{
        display:flex;
        flex-direction: column;
    
    }
    .logoone{
            width: 250px;
            align-items: center;
            margin: auto;
    }
    .footer__links{
        margin-top: 0px;
    }
    
    .footer__pr{
        font-size: 13px;
        width: 90%;
        text-align: center;
        margin: auto;
    }
    .footer__container article{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.2rem;
        text-align: center;
        margin-left: 10px;
    
    }
    .div_links{
        display: flex;
        gap: 20px;
        align-items: center;
        margin: auto;

    }
    .footer__container article p {
        margin-top: 0.5rem;
    }
    
    .footer__container article h4{
        margin-bottom: 0.6rem;
        font-size: 22px;
    }
    .footer__links{
        font-size: 20px;
        padding: 10px;
    }
    .footer_social{
        display: flex;
        gap: 1rem;
        align-items: center;
        margin: auto;
        margin-top: 1.6rem;
    }
    
    .footer_social a {
        background-color: rgb(85, 165, 199);
        padding: 0.8rem;
        border: 1px solid transparent;
        border-radius:20px;
    }
    
    .footer_social a svg{
        color: black;
    }
    
    .footer_social a:hover{
        border-color: black;
        background-color: white;
    }
    
    .copyright{
        font-size: 13px;
        color: black;
        text-align: center;
        padding: 1.5rem 0;
        border-top:  2px solid white;
        margin-top: 5rem;
    }
}