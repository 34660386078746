.about_section_img{
    object-fit: cover;
}
.about_section_img1{
    border-radius: 50%;
    width: 20rem;
    overflow: hidden;
    transform: skew(1deg);
    transition:  all 500ms ease;
}
.about_section_img:hover{
    transform: skew(0);
}

.about_section_cont h2,h4{
    margin-bottom: 0.5rem;

}

.about_section_cont p{
    width: 90%;
    margin-bottom: 1rem;

}

.MobileView{
    display: none;
}

.about_story_containers{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10%;
    align-items: center;
    margin-left: 90px;
    margin-bottom: 3rem;
    margin-top: 1rem;
}


.about_story_container{
    display: grid;
    grid-template-columns: 30% 50%;
    gap: 10%;
    align-items: center;
    margin-left: 90px;
    margin-bottom: 3rem;
    margin-top: 1rem;
}

.about_sec_img{
    display: grid;
    grid-template-columns: 70% 20%;
    gap: 10%;
    align-items: center;
    margin-left: 90px;
    margin-bottom: 3rem;
}


.about_sec_container{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 8%;
    align-items: center;
    margin-left: 90px;
    margin-bottom: 3rem;
}


/* staffs */

.staffs_con h2{
    text-align: center;
    margin: 30px;
}
.staffs_flex{
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 10px;
}

.staffs_flex img{
    width: 10rem;
    height: 10rem;
}
.doctorsname img{
    align-items: center;
    margin: auto;
}
.doctorsname h4{
    text-align: center;
}









/* media quries (medium screen) */
@media screen and (max-width:1024px) {
    .about_section_img{
        width: 60%;
    }

    .about_section_cont h2{
        margin-bottom: 1.2rem;
    }
    .about_story_container{
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .about_sec_container{
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    /* staffs */

    .staffs_con h2{
        text-align: center;
        margin: 30px;
    }
    .staffs_flex{
       display: flex;

    }

    .staffs_flex img{
        width: 10rem;
        height: 10rem;
    }

}

/* media quries (small screen) */
@media screen and (max-width: 600px) {
    .about_section_img{
        width: 300px;
        margin-inline: auto;
    }
    .about_story_container{
        grid-template-columns: 1fr;
        gap: 2rem;
        border-radius: 50px 15px; 
        margin-left: 30px;
        border: 3px solid rgb(85, 165, 199);
        align-items: center;
        margin: 10px 20px;
        padding: 20px;
    
    }



    .about_section_cont{
        text-align: center;
    }

    .about_section_cont p{
        width: 100%;
        align-items: center;
        margin: auto;
    }

    .about_sec{
        grid-row: 1;
    }

    /* staffs */

    .staffs_con h2{
        text-align: center;
        margin: 30px;
        font-size: 32px;
    }
    .staffs_flex{
        display: grid;
        flex-direction: column;
        grid-template-columns: 2fr 2fr;
        gap: 8%;
        margin-left:10px;
        padding-bottom: 50px;
    }

    .staffs_flex img{
        width: 8rem;
        height: 8rem;
    }

    .MobileView{
        display: block;
        width: 80%;
        margin-inline: auto;
    }
    
    .about_section_img1{
        display: none;
    }
    .about_story_containers{
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-left: 30px;
        border-radius: 15px 50px; 
        border: 3px solid rgb(85, 165, 199);
        align-items: center;
        margin: auto 20px;
        padding: 20px;
    }
}
