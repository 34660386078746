.main__header{
    width: 100%;
    height: calc(100vh -10rem);
    display: flex;
    flex-direction: column;
    place-items: center;
    background-image:url("../../../src/imgs/bgimg.jpg") ;
    background-size: cover;
    opacity: 90%;
}

.container{
    margin: 20% 2%;
    text-align: center;
    padding: 20px;

}

.main_text{ 
    font-size: 36px;
    color: whitesmoke;
}
.main__header-right p{
    color: whitesmoke;
    font-size: 12px;
    width: 60%;
    text-align: center;
    margin: auto;
    padding: 10px;
}
.btn.lg{
  background-color: whitesmoke;
  color: black;
  align-items: center;
  margin: auto;
  padding: 10px;
  font-size: 8px;
  border-radius: 2rem;
}




/* home care */

.whyKV {
  width: 100%;
  height: auto;
}

.homeCare {
  text-align: center;
  padding: 1rem;
}
.icon_pra {
    text-align: center;
  padding: 3rem;
  display: flex;
  justify-content: space-around;
}
.border{
    width: 300px;
    padding: 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.border:hover{
    transform: scale(1.05); 
    transition-duration: 1000ms;
    cursor: pointer;

}
.iconImg{
    align-items: center;
    margin: auto;
    object-fit: cover;  
    width: 250px;
    height: 250px;
}

/* vison */
.vison {
  padding: 2rem;
  background-color: rgb(218, 216, 216);
  width: 90%;
  align-items: center;
  margin: auto;
  margin-bottom: 30px;
  border-radius: 50px 1px;
}
.visionFlex {
  display: flex;
  gap: 50px;
}
.visionLeft {
  width: 100%;
  text-align: center;
  align-items: center;
  margin: auto;
  line-height:2rem;
}
.visionLeft > h2 {
  margin: 20px;
}
.orderList{
    font-size: 0.9rem;
    width: 100%;
}
.visionImg {
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
}

/* servics */

.servics {
  width: 100%;
  height: auto;
}

.servics_text {
  text-align: center;
  padding: 1rem;
}
.service_icon {
  padding: 3rem 9rem;
  display: flex;
  justify-content: space-evenly;
}
.iconImg {
  object-fit: contain;
}
.readmore1 {
  text-align: center;
  text-decoration: none;
}
.border h3{
    text-align: center;
}
.ser_pra {
  width: 70%;
  text-align: center;
  margin: auto;
  padding: 10px;
}
.icon{
    display: flex;
    align-items: center;
    gap: 4px;
}
.msgs{
    width: 35px;
}
.doc{
    width: 50px;
}

.phoneCall{
    width: 25px;
}
.icons{
    display: flex;
    justify-content: center;
    gap: 20px;
}

.pen{
    width: 35px;
}

/* get in touch */

.getInTouch {
    background-color: #cfc9d7;
background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23431b83' fill-opacity='0.08' fill-rule='evenodd'/%3E%3C/svg%3E");
    height: calc(110vh - 8rem);
    text-align: center;
    align-items: center;
    padding: 10px;
    margin: auto;
    color: black;
  }
  #name,
  #email,
  #query,
  #phone {
    border: 1px solid black;
    width: 30%;
    padding: 10px 8px;
  }
  #name:focus {
    background-color: #e3f4f4;
    color: rgb(0, 0, 0);
    font-size: large;
  }
  #email:focus {
    background-color: #e3f4f4;
    color: rgb(0, 0, 0);
    font-size: large;
  }
  #phone:focus {
    background-color: #e3f4f4;
    color: rgb(0, 0, 0);
    font-size: large;
  }
.msgBtn{
    padding: 20px;
    background-color: rgb(85, 165, 199); 
    font-family: poppins;
    border-radius: 20px;
    margin: 10px;
}
#email:focus {
  background-color: #e3f4f4;
  color: rgb(0, 0, 0);
  font-size: large;
}
#phone:focus {
  background-color: #e3f4f4;
  color: rgb(0, 0, 0);
  font-size: large;
}
.msgBtn {
  padding: 20px;
  background-color: rgb(85, 165, 199);
  font-family: poppins;
  border-radius: 20px;
  margin: 10px;
}
.inputBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.textName {
  text-align: right;
}

/* About */
.aboutus {
  padding: 2rem;
  background-color: rgb(218, 216, 216);
  width: 90%;
  align-items: center;
  margin: auto;
  margin-bottom: 30px;
  border-radius: 50px 1px;
}
.aboutFlex {
  display: flex;
  gap: 50px;
}
.aboutLeft {
  width: 60%;
  text-align: center;
  align-items: center;
  margin: auto;
}
.aboutLeft > h2 {
  margin: 20px;
}
.aboutusimg {
  width: 25rem;
  height: 20rem;
}
.aboutusimg{
    width: 25rem;
    height: 20rem;
}

.msgBtn {
    margin-top: 1rem;
    position: relative;
    padding: 10px 20px;
    border-radius: 50px;
    border: 2px solid rgb(61, 106, 255);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    background: transparent;
    color: #55a5c7;
    overflow: hidden;
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }
  
.msgBtn:hover {
    color: white;
    background: rgb(61, 106, 255);
    box-shadow: 0 0 30px 5px rgba(0, 142, 236, 0.815);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  
  .msgBtn:hover::before {
    -webkit-animation: sh02 0.5s 0s linear;
    -moz-animation: sh02 0.5s 0s linear;
    animation: sh02 0.5s 0s linear;
  }
  
  .msgBtn::before {
    content: '';
    display: block;
    width: 0px;
    height: 86%;
    position: absolute;
    top: 7%;
    left: 0%;
    opacity: 0;
    background: #fff;
    box-shadow: 0 0 50px 30px #fff;
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    -o-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }
  
  @keyframes sh02 {
    from {
      opacity: 0;
      left: 0%;
    }
  
    50% {
      opacity: 1;
    }
  
    to {
      opacity: 0;
      left: 100%;
    }
  }
  
  .msgBtn:active {
    box-shadow: 0 0 0 0 transparent;
    -webkit-transition: box-shadow 0.2s ease-in;
    -moz-transition: box-shadow 0.2s ease-in;
    transition: box-shadow 0.2s ease-in;
  }
  .maps{
    width: 100%;
    border-radius: 20px;
    border: 10px solid #55a5c7;
  }


  .youtube{
    background-color: #7e8b89;
    padding: 20px;
  }
  .ytFlex{
    padding: 20px;
    margin: 0px 60px;
    border-radius: 20px;
    display: flex;
    border: 1px solid rgba(245, 245, 245, 0.418);
    justify-content: space-evenly;
  }
  .ytVideo{
    width: 400px;
    height:250px;
    border-radius: 20px;
  }












/* media screen  mobile view */

@media screen and (max-width : 460px) {    
    .main__header{
        width: 100%;
        height: calc(100vh - 25rem);
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        place-items: center;
        background-image:url("../../../src/imgs/bgimg.jpg") ;
        background-size:cover;
        padding: 10px;
    }
    
    .container{
        margin: 70% 0%;
        text-align: center;
        padding: 10px;
    
    }
    
    .main_text{
        font-size: 16px;
        color: white;
    }
    .main__header-right p{
        color: whitesmoke;
        font-size: 10px;
        width: 90%;
        text-align: center;
        margin: auto;
        padding: 5px;
    }
    
    

    .btn.lg{
        background-color: #55a5c7;
        align-items: center;
        margin: auto;
        padding: 10px;
        font-size: 10px;
        border-radius: 2rem;
    }
    
    

        /* home care */

        .whyKV{
            width: 100%;
            height: auto;
        }

        .homeCare{
            text-align: center;
            padding: 1rem;
        }
        .icon_pra{
            padding: 3rem 9rem;
            display: flex;
            flex-direction: column;
            row-gap: 50px;
            align-items: center;
            margin: auto;
        }
       
        .icon_pra h3{
            font-size:18px;
            width: 100%;
        }

        .border{
            width: 350px;
            padding: 1rem;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }
        
        .border:hover{
            transform: scale(1.05); 
            transition-duration: 1000ms;
            cursor: pointer;
        
        }

        /* vison */
        .vison {

            padding: 2rem;
            background-color: rgb(218, 216, 216);
            width: 98%;
            align-items: center;
            margin: auto;
            margin-bottom: 30px;
            border-radius: 50px 1px;
        }
        .visionFlex {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .visionLeft {
            width: 105%;
            text-align: center;
            align-items: center;
            margin: auto;
            line-height:2rem;
        }
        .visionLeft > h2 {
            margin: 20px;
        }
        .orderList{
            font-size: 1rem;
            width: 100%;
        }
        .visionImg {
            width: 20rem;
            height: 20rem;
            border-radius: 50%;
        }
        /* servics */

        .servics{
            width: 100%;
            height: auto;
        }

        .servics_text{
            text-align: center;
            padding: 1rem;
        }
        .servics_text h2 {
            font-size: 2rem;
        }
        .service_icon{
            padding: 3rem 5rem;
            align-items: center;
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
        .iconImg{
            object-fit: contain;
            width: 300px;
            height: 300px;
        }
        .readmore1{
            text-align: center;
            text-decoration: none;
        }
        .ser_pra{
            width: 100%;
            text-align: center;
            margin: auto;
            padding: 10px;
            font-size: 1rem;
        }
        .service_icon div h3{
            width: 100%;
            font-size: 22px;
        }
        .readmore1{
            font-size: 22px;
        }


        /* get in touch */
        .getInTouch{
            height: auto;
            text-align: center;
            align-items: center;
            margin: auto;
        }
        .getInTouch div p{
            font-size: 16px;
        }
        .getInTouch div h1{
            font-size: 27px;
        }
        #name{
            border: 1px solid black;
            width: 90%;
            padding: 10px 150px ;
        }
        .msgBtn{
            font-size: 18px;
            padding: 15px 20px;
            background-color: rgb(85, 165, 199); 
            color: black;
            font-family: poppins;
            border-radius: 20px;
            margin: 10px;
        }
        .inputBox{
            font-size: 28px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
        }
        .textName{
            text-align: right;
        }

        /* About */
        .aboutus{
            padding: 1rem;
            background-color: rgb(218, 216, 216);
            width: 95%;
            align-items: center;
            margin: auto;
            margin-bottom: 30px;
            border-radius: 50px 1px;

        }
        .aboutFlex{
            display: flex;
            flex-direction: column;
            gap: 50px;
        }
        .aboutLeft{
            width: 100% ;
            text-align: center;
            align-items: center;
            margin: auto;
        }
        .aboutLeft>h2{
            font-size: 2rem;
            margin: 20px;
        }
        .aboutLeft>p{
            width: 100%;
            font-size: 16px;
            padding-bottom: 10px;
        }
        .aboutusimg{
            width: 100%;
            height: 100%;
        }    

        #name,
        #email,
        #query,
        #phone {
            border: 1px solid black;
            width: 90%;
            padding: 10px 10px;
        }
        #name:focus {
            background-color: #e3f4f4;
            color: rgb(0, 0, 0);
            font-size: large;
        }
        #email:focus {
            background-color: #e3f4f4;
            color: rgb(0, 0, 0);
            font-size: large;
        }
        #phone:focus {
            background-color: #e3f4f4;
            color: rgb(0, 0, 0);
            font-size: large;
  }
  .youtube{
    background-color: #7e8b89;
    padding: 20px;
  }
  .ytFlex{
    padding: 20px;
    align-items: center;
    margin: auto;
    border-radius: 20px;
    display: flex;
    gap: 20px;
    border: 1px solid rgba(245, 245, 245, 0.418);
    flex-direction: column;
  }
  .ytVideo{
    width: 350px;
    height:250px;
    border-radius: 20px;
  }
 
}
