nav{
    height: 6rem;
    width: 100vw;
    background-color: whitesmoke;
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    right: 100;
    z-index: 99;
}

.nav_logo{
  width: 200px;
  height: auto;
}

/* only show medium and small screen */
.nav__toggle {
  display: none;
}

.nav_continer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo {
  width: 7rem;
  display: block;
}

.nav_links {
  display: flex;
  gap: 3.5rem;
  align-items: center;
}
.nav_links a {
  transition: all 500ms ease;
  color: black;
}

.nav_links a:hover {
  color: red;
}

.active-nav {
  position: relative;
}

.active-nav::after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background-color: whitesmoke;
  position: absolute;
  left: calc(50% -0.6rem);
  transform: rotate(45deg);
  margin-top: 0.1rem;
}

/* media quries */
@media screen and (max-width: 1024px) {
  .nav__toggle {
    display: inline-block;
    background-color: transparent;
    font-size: 1rem;
    cursor: pointer;
  }
  .nav__toggle svg {
    color: black;
    width: 25px;
    height: 25px;
  }
  .nav_links {
    position: absolute;
    top: 100%;
    right: 0;
    flex-direction: column;
    gap: 0;
    perspective: 400px;
  }
  .active-nav,
  .active-nav::after {
    display: none;
  }

  .nav_links li {
    height: 4rem;
    width: 100%;
    box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.5);
    animation: navAnimation 600ms ease forwards;
    transform: rotateX(90deg);
    opacity: 0;
    transform-origin: top;
  }
  .nav_links li:nth-child(2) {
    animation-delay: 200ms;
  }
  .nav_links li:nth-child(3) {
    animation-delay: 400ms;
  }
  .nav_links li:nth-child(4) {
    animation-delay: 600ms;
  }
  .nav_links li:nth-child(5) {
    animation-delay: 800ms;
  }
  .nav_links li:nth-child(6) {
    animation-delay: 1s;
  }
  .nav_links li:nth-child(7) {
    animation-delay: 1.2s;
  }
  @keyframes navAnimation {
    to {
      transform: rotateX(0);
      opacity: 1;
    }
  }

  .nav_links li a {
    background-color: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 5rem 1rem 3rem;
  }
  .show__nav {
    display: flex;
  }
  .hide__nav {
    display: none;
  }
  .nav_logo{
    align-items: center;
    margin: 0rem 0 0 1rem;
    width: 200px;
  }
}
